//EL MPP/SPP Markdown Mobile Fix
.quickshop-inline {
  .select-markdown {
    background-position: 10px center;
  }
}

.spp-product {
  &__quantity,
  .select-markdown {
    background-position: 10px center;
  }
}
//Product Foundation VTO
.product-foundation-vto {
  &__btn {
    &.cta-vto {
      @include swap_direction(padding, 0 20px 0 5px);
      background-size: 13px;
      font-size: 15px;
    }
  }
  &__overlay & {
    &__overlay-content {
      #{$rdirection}: 50%;
      #{$ldirection}: initial;
    }
  }
  &__overlay-button {
    &.button {
      background-position: 110px;
    }
  }
  &__container {
    ul.shade-list-vto {
      li {
        .vto-filter-text {
          @include transform(translateX(-50%));
          font-size: 11px;
          position: absolute;
          #{$rdirection}: 50%;
        }
      }
    }
  }
  &__mobile-ctrl {
    .container {
      .vto-info {
        .product-sub-heading {
          font-size: 11px;
        }
      }
    }
  }
}

.sd-product-grid {
  .elc-grid-item-product {
    .elc-product-brief {
      .elc-carousel-section-wrapper {
        .elc-slider-arrow-wrapper.slick-next {
          #{$rdirection}: auto;
        }
      }
      .elc-product-price-row-wrapper {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .elc-product-grid-wrapper {
    .elc-sort-wrapper {
      select {
        background-position: 0;
      }
    }
  }
}

.main-content {
  .tabbed-products-block {
    .grid {
      &--mpp {
        &__carousel {
          .carousel-controls {
            .slick-next {
              #{$rdirection}: auto;
              #{$ldirection}: 0;
              top: 0;
            }
            .slick-prev {
              #{$rdirection}: 0;
              #{$ldirection}: auto;
              top: 0;
            }
          }
        }
      }
    }
  }
  .content-formatter {
    .slick-initialized {
      .slick-prev {
        #{$rdirection}: 0;
        #{$ldirection}: auto;
      }
      .slick-next {
        #{$rdirection}: auto;
        #{$ldirection}: 0;
      }
    }
  }
}
