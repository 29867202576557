input[type='radio'].error ~ label {
  color: $color-red;
}

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: $rdirection;
}

.cleft {
  clear: $ldirection;
}

ul.menu {
  text-align: $ldirection;
}

.page-footer {
  .responsive-footer {
    ul {
      &.menu {
        li {
          @include swap_direction(margin, 0);
          &.collapsed,
          &.expanded,
          &.leaf {
            @include swap_direction(padding, 0);
          }
        }
      }
    }
    .footer-signup-email {
      .email_signup__terms {
        clear: both;
        padding-top: 18px;
        label {
          line-height: 14px;
        }
      }
    }
  }
}

nav {
  .field {
    &-mobile-menu,
    &-menu {
      ul {
        &.menu {
          text-align: center;
          a.loyalty {
            display: none;
          }
        }
      }
    }
  }
}

.footer-phone {
  .footer-phone__link {
    background-position: 150px -3719px;
    &:hover {
      background-position: 150px -3719px;
    }
  }
}

.wishlist-page {
  .account-favorites__list {
    li {
      margin: 0 3% 33px 3%;
      width: 44%;
    }
  }
}

.cs-quick-info__block {
  &--store-locator {
    .cs-quick-info__title {
      background-position: 47px -1838px;
    }
  }
  &--live-chat {
    .cs-quick-info__title {
      background-position: 55px -1788px;
    }
  }
  &--email-us {
    .cs-quick-info__title {
      background-position: 97px -1888px;
    }
  }
  &--call-us {
    .cs-quick-info__title {
      background-position: 84px -1938px;
    }
  }
}

.section-foundation-finder {
  .ff-quiz {
    .noUi-horizontal {
      .noUi-handle {
        #{$ldirection}: initial;
        #{$rdirection}: -10px;
        position: absolute;
      }
    }
    &__nav-item {
      background-position: 15px 15px;
    }
    &__slide {
      &.active {
        .ff-quiz {
          &__nav-item {
            background-position: 15px -68px;
          }
        }
      }
    }
  }
  .ff-results {
    .ff-results {
      &__matches-sub-headline,
      &__regimen-steps {
        display: none;
      }
    }
  }
}

.ee-mb-tile__content {
  img {
    @media #{$brand-story-one-col} {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
  }
}

.section-esteestories {
  .item-list {
    ul {
      li {
        margin: 0px;
      }
    }
  }
}

.slick-carousel,
.slick-slider {
  .slick-prev {
    left: 12px;
    right: auto;
  }
  .slick-next {
    right: 12px;
  }
}

.slick-slider {
  .slick-slide {
    float: $ldirection;
  }
}

.quickshop-inline {
  .product__inventory-status {
    a.notify_me {
      @include swap_direction(padding, 10px 13px);
    }
  }
  &__retail-price {
    color: #808080;
    text-decoration: line-through;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.bisPcIframe {
  height: 100%;
  width: 100%;
}

.device-mobile {
  #colorbox.welcome15-overlay.alt_layout1 {
    .email_popover {
      .email_popover__social_icons {
        top: 585px;
        padding-top: 23px;
      }
      .email_signup__terms {
        clear: both;
      }
    }
    #cboxContent {
      #cboxClose {
        text-indent: -9999px;
      }
    }
  }
  &.section-double-wear-cushion-stick {
    #content.main-content {
      overflow-x: hidden;
      .double-wear-cushion-stick__module {
        .double-wear-cushion-stick__headers {
          #double-wear-cushion-stick__header3-mob {
            margin-top: 50.4%;
          }
        }
        #double-wear-cushion-stick__how-to-text1 {
          margin-top: 5%;
        }
        #double-wear-cushion-stick__hero-img-text4 {
          margin-top: 126% !important;
        }
        &#double-wear-cushion-stick__module-2 {
          margin-top: 16.65%;
        }
        #double-wear-cushion-stick__parallax {
          margin-top: 58%;
        }
        .double-wear-cushion-stick__hovers {
          margin-top: 155%;
          &.double-wear-cushion-stick__hovers-how-to {
            margin-top: 118%;
          }
        }
      }
    }
  }
}

#supreme-power_container {
  .video_container.active {
    .video_wrapper {
      .video.active {
        position: absolute;
      }
    }
  }
}

.responsive-carousel {
  .flexslider {
    .flex-direction-nav {
      .flex-prev {
        left: 0;
        right: auto;
        text-align: inherit;
      }
      .flex-next {
        right: 0;
        left: auto;
        text-align: inherit;
      }
    }
  }
}

#supreme-power_container.boutique {
  .mpp {
    .mpp__product-sort-container {
      display: none;
    }
  }
}

.page-product ul.shade-list {
  li {
    a.swatch {
      &.swatch--selected {
        &:hover,
        &.selected {
          @include swap_direction(border, 1px solid #e1c27c);
        }
      }
    }
  }
}

#ultimate-diamond_container_mobile {
  &.boutique {
    direction: ltr;
    text-align: right;
  }
}

.spp {
  .spp-product__price-size {
    font-weight: normal;
    color: #808080;
    padding-bottom: 5px;
    .price-content {
      margin-left: 10px;
      float: right;
    }
  }
  .product_brief__discount-price {
    font-weight: bold;
    padding-bottom: 10px;
    .price-content {
      margin-left: 10px;
      float: right;
    }
  }
  .spp-product__description {
    .spp-product__retail-price {
      text-decoration: line-through;
      font-weight: bold;
      color: #808080;
    }
  }
}

.mpp {
  .product-brief {
    &__price,
    &__price-size {
      .brand-renutriv & {
        color: $color-white;
      }
      font-weight: bold;
      color: $color-black;
      font-size: 20px;
    }
    &__quickshop-container {
      .quickshop-inline__price-size {
        font-size: 12px;
        color: $color-darker-gray;
      }
    }
    &__reviews {
      display: none;
    }
  }
  .product_brief {
    &__discount-price {
      font-weight: bold;
      font-size: 12px;
    }
    &__ppu-price {
      .brand-renutriv & {
        color: $color-white;
      }
      font-size: 10px;
      color: $color-black;
    }
  }
}

.customer-service {
  .sidebar-page__sidebar {
    .level-1 {
      background-position: -452px -4444px;
    }
    .active-trail {
      .level-1 {
        background-position: -452px -4324px;
      }
    }
  }
}

.ecommerce {
  #BVRRSummaryContainer {
    display: none;
  }
  .spp-product__reviews {
    display: none;
  }
  .spp_reviews {
    display: none;
  }
}

.button-explore-wrapper--mobile {
  .button-explore-label {
    background-position: 0px -3276px;
    font-size: 20px;
    @include swap_direction(padding, 0px 70px 0px 0px);
    width: 55%;
  }
}

.email_popover {
  &__content {
    .email_signup__terms {
      label {
        .label::after {
          border: 1px solid #cccccc;
          background-image: url('/media/images/pro_active_popup/transparent_close_x.png');
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}

.page-wrapper {
  .product__inventory-status {
    .product__inv-status-item {
      color: $color-red;
    }
  }
}

.page-product-victoria-beckham-estee-lauder {
  .product__inventory-status-container {
    .button--dark {
      display: none;
    }
  }
}

.spp-product__additional-section {
  .spp-howtouse {
    .spp-howtouse {
      &__section1 {
        .spp-howtouse {
          &__header.headline--secondary {
            span {
              color: #040a2b;
              font-family: $optimaregular;
            }
          }
        }
      }
    }
  }
}

.product-brief {
  .product-brief__image {
    padding-bottom: 0 !important;
    height: 184px;
  }
}

#facechart-container {
  .facechart-intro {
    .column {
      .mobile {
        h2 {
          font-family: $assistant, $optimaregular;
        }
      }
    }
  }
  .facechart-steps-container {
    .slide_wrapper {
      blockquote.mobile {
        font-family: $assistant, $optimaregular;
        p,
        span {
          font-family: $assistant, $optimaregular;
        }
      }
    }
  }
}

.appt-book {
  .appointments-container {
    .mobile-phone-fieldset {
      .appt-book-mobile {
        width: 76% !important;
        float: right !important;
      }
      .appt-book-mobile-prefix {
        width: 24% !important;
        border-left: none;
        float: right !important;
      }
    }
    .pikaday-container {
      .pika-title {
        .pika-next {
          float: left;
          background: transparent
            url('/media/export/cms/appointment_booking/icons/cal_arrow_left.png') no-repeat !important;
        }
        .pika-prev {
          float: right;
          background: transparent
            url('/media/export/cms/appointment_booking/icons/cal_arrow_right.png') no-repeat !important;
        }
      }
    }
  }
  .appointment-container {
    .appointment-details {
      .artist {
        display: none !important;
      }
    }
  }
  .selectBox {
    background-position: 10px 50% !important;
  }
  .confirmation-container {
    .artist {
      display: none;
    }
  }
}

.sppmg,
.dwcc-carousel {
  &__carouselContainer {
    @include swap_direction(direction, ltr);
  }
  &__slidePrice {
    @include swap_direction(direction, rtl);
  }
}

.page-header {
  .page-navigation__menu {
    @media #{$large-down} {
      ul.menu li {
        margin: 0;
      }
      .menu-ref,
      .menu__item--lvl-3,
      .menu__item--lvl-5 {
        &.menu__item--back {
          &:before {
            background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
          }
        }
      }
      .menu-ref--has-children .menu-ref__title,
      .menu__item--category-expandable {
        &:after {
          background-image: url('/media/export/cms/gnav/gnav_icons_rtl.png');
        }
      }
    }
  }
}

.section-aerin,
.brand-aerin,
.section-re-nutriv,
.brand-renutriv {
  .page-header {
    &#{$alt} {
      .page-navigation__menu {
        a {
          color: $color-dark-navy;
        }
      }
    }
  }
}

h3 {
  &.el-product_category,
  &.product-brief__header,
  &.spp-product__header {
    direction: ltr;
  }
}

h4 {
  &.product-brief__header {
    direction: ltr;
  }
}

.cart-confirm {
  &__header {
    direction: ltr;
  }
}
