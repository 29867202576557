@import '../../../estee_base/scss/theme-bootstrap';

.loyalty_mrkt_ldng {
  max-width: 1366px;
  margin: 0 auto;
  &:before {
    background: unset;
  }
  .see_my_rewards {
    .form-submit {
      width: auto;
    }
  }
  .loyalty_join_signin {
    margin: 35px 35px 35px 35px;
    text-transform: none;
    .form-submit {
      width: 225px;
      display: inline;
      &.btn_sign_in {
        border: 1px solid $color-navy;
        background-color: $color-white;
        color: $color-navy;
      }
    }
    .btn_join {
      margin: 0;
    }
    .offer-percent {
      color: $color-navy;
      letter-spacing: 0.02em;
      font-size: 30px;
      font-family: $font-bauerbodoni-roman;
    }
    .offer-percent-number {
      margin-top: 0;
      text-transform: lowercase;
      letter-spacing: 0.05em;
    }
    .offer-signin {
      margin-bottom: 0;
      font-size: 12px;
    }
    .not_loyalty_join_now {
      display: block;
      label {
        display: inline-block;
        &:before,
        &:after {
          top: 7px;
        }
      }
      &.elist-ldng-gdpr {
        .info-link-gdpr {
          display: none;
        }
      }
    }
    p {
      text-transform: none;
      font-size: 12px;
      letter-spacing: 0.05em;
    }
    .terms-checkbox-label {
      display: block;
    }
    .js-loyalty-gdpr-signup {
      display: block;
      font-weight: 400;
      .text--checkbox-label {
        width: 100px;
        margin: 0 auto;
        margin-top: 10px;
      }
      label[for='loyalty_join_signin_sms_promotions'] {
        display: none;
      }
    }
    #loyalty_join_signin_phone_number {
      display: none;
    }
    .info-link-gdpr {
      font-size: 12px;
    }
  }
  .btn_sign_in {
    display: block;
  }
  .loyalty_tiers {
    max-width: 1090px;
    margin: 0 auto;
    padding: 0px 20px;
    .tier {
      width: 100%;
      float: #{$ldirection};
      color: $color-navy;
      @media #{$large-up} {
        width: 33%;
      }
      .loyalty-catgory-name {
        font-size: 25px;
        text-transform: uppercase;
        border-bottom: 1px solid $color-navy;
        width: 180px;
        padding-bottom: 10px;
        text-align: #{$ldirection};
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
        @media #{$large-up} {
          font-size: 30px;
          width: 82%;
        }
        &.second-catgory {
          width: 270px;
          @media #{$large-up} {
            width: 82%;
          }
        }
        &.final-catgory {
          width: 165px;
          @media #{$large-up} {
            width: 82%;
          }
        }
      }
      .tier_left {
        width: 100%;
        &.benefits_hover_tier_left_off {
          color: $color-navy;
        }
        .points-range {
          text-align: #{$ldirection};
          display: block;
          width: 100%;
          padding-top: 15px;
          font-size: 12px;
          letter-spacing: 0.15em;
        }
      }
      .tier_right {
        width: 95%;
        @media #{$medium-up} {
          padding: 8px 3px 0px;
        }
        .tier_bold {
          font-size: 14px;
          font-weight: bold;
        }
        &.three-tier {
          padding: 0;
        }
        &.benefits_hover_tier_left_off {
          color: $color-navy;
        }
        .loyal-benfit {
          @include swap_direction(padding, 0px 25px 0px 15px);
        }
        .loyal-catgory-benfits {
          list-style-type: disc;
          list-style-position: outside;
          padding-#{$rdirection}: 15px;
          &:before {
            display: none;
          }
        }
      }
    }
    .sub_head {
      font-size: 15px;
      text-transform: capitalize;
      letter-spacing: 0.05em;
    }
    .loyal-love-head {
      text-transform: uppercase;
      font-size: 40px;
      letter-spacing: 0.02em;
      font-family: $font-bauerbodoni-roman;
    }
  }
  .loyalty_points {
    clear: both;
    background-color: $color-white;
    color: $color-navy;
    .points_earn {
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 40px;
      font-family: $font-bauerbodoni-roman;
      &__decscription {
        font-size: 15px;
        letter-spacing: 0.05em;
      }
    }
    .loyalty_question {
      .loyalty_learn_more {
        color: $color-navy;
      }
    }
  }
  .beauty-header {
    font-style: italic;
    text-transform: uppercase;
    font-size: 40px;
    color: $color-navy;
    margin-top: 25px;
    font-family: $font-bauerbodoni-roman;
    line-height: 1;
  }
  .beauty-content {
    color: $color-navy;
    margin-bottom: 0;
  }
  .elist-banner {
    height: 30vw;
    background-image: url('/media/loyalty/elist-banner.jpg');
    background-size: cover;
    max-width: 1366px;
    max-height: 400px;
    @if $loyalty-banner-cr23update {
      background: url('#{$elist-banner-mobile}') no-repeat;
      background-size: contain;
    }
    @media #{$medium-up} {
      @if $loyalty-banner-cr23update {
        background: url('#{$elist-banner-desktop}') no-repeat;
        height: 39vw;
        max-height: 494px;
        max-width: 1366px;
        background-size: cover;
        margin: 0 auto;
      }
    }
    .elist-banner {
      &-header {
        @include swap_direction(padding, 14vw 0vw 0vw 4vw);
        font-size: 4.3vw;
        color: $color-white;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.01em;
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            color: $color-black;
            font-size: 33px;
            padding: 16% 0 0;
            width: 45%;
          }
        }
        @media #{$medium-down} {
          @if $loyalty-banner-cr23update {
            font-size: 24px;
            width: 46%;
          }
        }
        @media #{$xxlarge-up} {
          @include swap_direction(padding, 190px 0px 0px 0px);
          font-size: 60px;
          @if $loyalty-banner-cr23update {
            font-size: 41px;
            padding: 16% 0 0;
            width: 46%;
          }
        }
      }
      &-content {
        margin: 10px;
        font-size: 1.32vw;
        color: $color-white;
        font-weight: 500;
        letter-spacing: 0.15em;
        @media #{$medium-up} {
          @if $loyalty-banner-cr23update {
            color: $color-black;
            width: 45%;
            font-family: $font-bauerbodoni-roman;
            font-size: 13px;
          }
        }
        @media #{$xxlarge-up} {
          font-size: 18px;
          width: 46%;
        }
      }
    }
  }
}

.field-mobile-template {
  .loyalty_market {
    .loyalty_market {
      &__excited__benefits,
      &__excited__enthusiastic__benefits {
        font-size: 12px;
        text-transform: inherit;
      }
      &__btn__signin {
        border: 1px solid $color-navy;
        background-color: $color-white;
        color: $color-navy;
        display: block;
      }
      &__points {
        @include swap_direction(padding, 18px 5px 28px);
        background-color: $color-white;
        color: $color-navy;
        &__offer {
          text-transform: initial;
          font-size: 15px;
          letter-spacing: 0.05em;
          line-height: 1.6;
          br {
            display: none;
          }
        }
        &__list {
          line-height: 1.6;
        }
        &__link {
          font-size: 15px;
        }
      }
      &__tier__header {
        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 0.02em;
        font-family: $font-bauerbodoni-roman;
      }
      &__tier__points {
        font-weight: bold;
        font-size: 12px;
      }
      &__tiers__text {
        text-transform: initial;
        font-size: 15px;
        letter-spacing: 0.05em;
        br {
          display: block;
        }
      }
      &__tiers__header {
        letter-spacing: 0.02em;
        font-size: 35px;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
      }
      &__cta__offer_discount {
        margin: 15px 0px;
        font-size: 28px;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
        line-height: 1.2;
      }
      &__cta__offer_member {
        font-weight: 100;
        text-transform: lowercase;
        font-size: 15px;
        letter-spacing: 0.05em;
      }
      &__cta__offer {
        font-size: 12px;
        letter-spacing: 0.05em;
        margin: 15px;
      }
      &__header {
        margin: 0px 15px;
        font-size: 40px;
        line-height: 1;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
      }
      &__points__header {
        font-size: 35px;
        line-height: 1.25;
        text-transform: uppercase;
        font-style: italic;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.02em;
      }
      &__text {
        font-size: 15px;
        line-height: 1.6;
        margin: 15px 0px;
        br {
          display: block;
        }
      }
      &__tier__list {
        padding-#{$ldirection}: 15px;
        &__item {
          list-style-position: outside;
          line-height: 1.6;
        }
      }
      &__points__container {
        background: unset;
      }
      &__cta {
        background: unset;
      }
    }
  }
  .elist-banner {
    height: 100vw;
    background-image: url('/media/loyalty/account-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    @if $loyalty-banner-cr23update {
      height: 117vw;
      background-image: url('#{$elist-banner-account-login}');
    }
    .elist-banner {
      &-header {
        font-size: 10vw;
        color: $color-white;
        letter-spacing: 0.03em;
        padding-top: 54vw;
        font-family: $font-bauerbodoni-roman;
        @if $loyalty-banner-cr23update {
          color: $color-black;
          padding-top: $elist-banner-account-login-mobile-padding-top;
          font-size: 6.5vw;
          @media #{$medium-up} {
            font-size: 5.5vw;
          }
        }
      }
      &-content {
        letter-spacing: 0.15em;
        margin: 4px;
        font-size: 4vw;
        color: $color-white;
        font-family: $akzidenz;
        @if $loyalty-banner-cr23update {
          color: $color-black;
          @media #{$medium-up} {
            font-size: 2vw;
          }
        }
      }
    }
  }
  .loyalty_multi_image_tout {
    .loyalty_multi_image_tout {
      &_left {
        position: static;
      }
      &_right {
        padding-top: 0;
      }
    }
  }
}

#cboxOverlay {
  &.signin-overlay-loyalty,
  &.loyalty-offer-overlay {
    background: transparent;
  }
}

.loyalty-offer-overlay {
  border: 1px solid $color-medium-gray;
  background: $color-white;
  #cboxContent {
    padding: 0;
    .loyalty_popover {
      @media #{$medium-up} {
        padding: 30px 80px;
      }
      padding: 30px 0px;
      color: $color-navy;
      .loyalty_popover {
        &__input {
          .form-submit {
            border: 1px solid $color-navy;
            color: $color-white;
            background: $color-navy;
            width: auto;
          }
          .form-text {
            margin-bottom: 10px;
          }
          .form-text,
          .form-password {
            @include input-placeholder {
              color: $color-medium-gray;
            }
            border: 1px solid $color-gray;
            color: $color-navy;
          }
          .enrollment-form-submit {
            &:disabled {
              background: $color-light-gray;
              border: 1px solid $color-light-gray;
            }
          }
        }
        &__link,
        &__checkbox-link,
        &__terms,
        &__privacy {
          color: $color-navy;
        }
        &__checkbox-container {
          width: auto;
          margin-top: 15px;
          .loyalty_popover__checkbox {
            &-label {
              padding-#{$ldirection}: 30px;
              &:before {
                background: $color-white;
                border: 1px solid $color-navy;
              }
              &:after {
                background-image: url('/media/loyalty/crossmark.png');
                border: 1px solid $color-navy;
              }
            }
            &-link {
              color: $color-navy;
            }
          }
        }
        &__button {
          .button {
            border: 1px solid $color-navy;
            color: $color-white;
            background: $color-navy;
          }
        }
        &__terms {
          clear: both;
          margin-top: 0;
        }
        &__header {
          font-size: 40px;
          font-style: italic;
          font-family: $font-bauerbodoni-roman;
          text-transform: uppercase;
          letter-spacing: 0.01em;
          line-height: 1;
        }
        &__choose {
          text-transform: initial;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.05em;
        }
        &__promo-header {
          font-size: 15px;
          line-height: 18px;
          font-family: $akzidenz;
          letter-spacing: 0.05em;
          margin-top: 0;
        }
        &__promo {
          text-transform: initial;
          letter-spacing: 0.05em;
          margin: 15px 0px;
        }
        &__sub-header {
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          margin-top: 15px;
          line-height: 1.2;
        }
        &__status {
          letter-spacing: 0.05em;
          margin-bottom: 0;
        }
        &__offer-note {
          margin-top: 10px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  #cboxClose {
    background-image: url('/media/images/pro_active_popup/close.jpg') !important;
    margin: 20px;
  }
}
/* Sign in popup thank you message */
.signin-overlay-wrapper {
  .sign-in-component {
    &--loyalty {
      &.registration-confirmation {
        padding: 20px 10px;
      }
    }
    .sign-in-component {
      &__confirm {
        .sign-in-component {
          &__header {
            margin-bottom: 15px;
          }
          &__registration-confirmation-copy {
            display: block;
            font-size: 15px;
            letter-spacing: 0.05em;
            font-family: $akzidenz;
            font-style: italic;
            font-weight: 100;
          }
          &__registration-confirmation-header {
            font-family: $font-bauerbodoni-roman;
            font-size: 32px;
            font-style: italic;
            font-weight: 100;
          }
          &__confirm-registration-fields {
            display: flex;
            flex-direction: column;
            max-width: 540px;
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          &__birthday-program {
            float: none;
            width: 100%;
            padding: 0;
            order: 1;
            .select-month,
            .select-day {
              &.error {
                ~ .select-month,
                ~ .select-day {
                  border: 1px solid $color-red;
                }
              }
            }
          }
          &__confirm-options {
            max-width: 540px;
            .button {
              float: #{$ldirection};
              margin-top: 10px;
              width: 115px;
            }
          }
          &__sms-opt-in {
            width: 100%;
            float: none;
            padding: 0;
            order: 2;
            margin-bottom: 0;
          }
          &__completion-error {
            color: $color-red;
            padding-top: 30px;
            line-height: 1.2;
          }
          &__mobile-conditions {
            line-height: 1.5;
          }
        }
        .error_messages_display {
          li {
            color: $color-red;
            font-size: 12px;
            line-height: 1.5;
            font-weight: 100;
            letter-spacing: 0.05em;
            font-family: $akzidenz;
          }
        }
      }
    }
  }
}
/* My feed Section */
.drawer-formatter {
  &__content {
    .my-feed-tray--loyalty {
      &:before {
        width: 100%;
      }
      .my-feed-tray {
        &__inner {
          .my-feed-tray__dual-col {
            padding: 30px 0px;
            width: 100%;
          }
          .my-feed-tray__col {
            &.my-feed-summary {
              display: none;
            }
          }
        }
      }
      .my-feed-loyalty-container {
        max-width: 600px;
        .loyalty-offer {
          max-width: 600px;
          &__img {
            @include swap_direction(padding, 1px 20px 0px 0px);
            float: #{$ldirection};
          }
          &__elist-header {
            @include headline--quinary--plain;
            line-height: 0.97;
          }
          &__right-container {
            overflow: hidden;
          }
        }
        .my-feed-loyalty-raf {
          margin-bottom: 5px;
        }
      }
      .my-feed-loyalty {
        &__right-container {
          overflow: hidden;
        }
        &__status-display {
          @include swap_direction(padding, 0px 10px 5px 0px);
          float: none;
          width: 100%;
          border-#{$rdirection}: none;
          letter-spacing: 0.02em;
        }
        &__zero-points {
          padding: 0;
          @media #{$medium-up} {
            letter-spacing: 0.05em;
            width: 100%;
          }
          @media #{$large-up} {
            letter-spacing: 0.15em;
            width: 66%;
          }
        }
        &__status-container {
          margin: 5px 0;
        }
        &__status-next {
          width: 95%;
          padding-#{$ldirection}: 0;
          .next-level {
            display: none;
          }
        }
      }
    }
  }
  &__close {
    #{$rdirection}: 15px;
  }
}

.registration-confirmation {
  .social-login,
  .signin-overlay__toggle {
    display: none;
  }
}

.signin-join-loyalty {
  .signin-overlay__toggle {
    display: none;
  }
}

.page-footer {
  clear: both;
}

.page-utilities {
  .page-utilities {
    &__signin-text {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.loyalty_popover {
  &__link-container {
    padding-top: 10px;
    clear: left;
  }
  .loyalty_popover {
    &__checkbox-label {
      font-size: 12px;
      letter-spacing: 0.05em;
    }
  }
}

.elc-user-state-logged-in {
  .page-utilities {
    .page-utilities,
    .utility-nav {
      &__signin-text {
        display: none;
      }
    }
  }
}

.js-loyalty-gdpr-signup {
  .text--checkbox-label {
    width: 100px;
    margin: 0 auto;
    margin-top: 10px;
  }
  label[for='loyalty_join_signin_sms_promotions'],
  #loyalty_join_signin_phone_number {
    display: none;
  }
  .js-loyalty-gdpr-communication {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
}

@if $loyalty-banner-cr23update {
  .loyalty-account-page {
    .loyalty__panel__about__landing {
      .loyalty_mrkt_ldng {
        .elist-banner {
          @media #{$medium-up} {
            height: 29vw;
            background-size: contain;
          }
          &-header {
            @media #{$medium-up} {
              font-size: 19px;
              width: 54%;
            }
            @media #{$large-up} {
              font-size: 25px;
              width: 50%;
            }
            @media #{$xxlarge-up} {
              font-size: 33px;
              width: 46%;
            }
          }
          &-content {
            @media #{$medium-up} {
              width: 54%;
            }
            @media #{$large-up} {
              width: 50%;
            }
            @media #{$xxlarge-up} {
              width: 46%;
            }
          }
        }
      }
    }
  }
}
