/* To display a Review description posted by user */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        margin-bottom: 0;
        .pr-rd-review-total {
          background: none;
          clear: both;
        }
        .pr-rd-review-header-contents {
          float: $ldirection;
          width: 100%;
          text-align: $ldirection;
          @media #{$medium-up} {
            width: 60%;
          }
          &:before {
            content: 'סנן לפי :';
            float: $ldirection;
            margin-top: 13px;
          }
          .pr-multiselect-button-אנימשתמשתבאסתילאודרבמשך {
            display: none;
          }
        }
        .pr-clearfix {
          clear: none;
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-main-header-search {
            float: $rdirection;
          }
          .pr-rd-review-header-sorts {
            float: $rdirection;
            padding-#{$rdirection}: 0;
            .pr-rd-sort-group {
              #{$rdirection}: 0;
              .pr-rd-sort {
                float: $rdirection;
              }
              &:before {
                top: 7px;
                content: 'מיין לפי :';
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-star-rating {
          float: $ldirection;
          clear: both;
        }
        .pr-rd-review-headline,
        .pr-rd-description-text,
        .pr-rd-bottomline {
          text-align: $ldirection;
          width: 100%;
        }
        .pr-rd-right {
          #{$rdirection}: -220px;
          #{$ldirection}: auto;
          text-align: $ldirection;
        }
        .pr-rd-helpful-action {
          float: $ldirection;
        }
        .pr-accordion {
          text-align: $ldirection;
          padding-#{$ldirection}: 0;
        }
        .pr-rd-footer {
          .pr-helpful-no {
            .pr-helpful-count:before {
              content: 'כן(';
            }
          }
          .pr-helpful-yes {
            .pr-helpful-count:before {
              content: 'לא(';
            }
          }
          .pr-rd-flag-review-container {
            text-align: $ldirection;
          }
        }
        .pr-modal-content {
          text-align: $ldirection;
          .pr-flag-review-fieldset {
            text-align: $ldirection;
          }
          .pr-flagging-radio {
            .pr-form-radio {
              position: relative;
              #{$ldirection}: 0;
            }
            span {
              margin-#{$ldirection}: 20px;
            }
          }
          .pr-form-control-error-icon {
            #{$ldirection}: auto;
            #{$rdirection}: 5px;
          }
        }
      }
    }
    /* stylelint-disable declaration-no-important */
    .pr-review-snapshot {
      .pr-snippet-rating-decimal {
        display: inline-block !important;
        font-family: $assistant !important;
        &:after {
          content: '5 מתוך' !important;
          float: $ldirection;
          padding-#{$rdirection}: 5px;
        }
      }
      .pr-review-snapshot-block-pros {
        .pr-filter-btn__buttonText {
          font-family: $assistant !important;
        }
      }
    }
    /* stylelint-enable declaration-no-important */
    .pr-rd-main-footer {
      clear: both;
    }
  }
}

#pr-write {
  #pr-war-form {
    .pr-form-group {
      .pr-rating-input-container {
        clear: both;
      }
    }
    .pr-control-label {
      float: $ldirection !important;
      text-align: $ldirection;
    }
    .tag-container,
    .pr-form-control-error-wrapper {
      float: $ldirection;
      width: 100%;
      .pr-cb-tag-container {
        float: $ldirection;
        width: 100%;
        @media #{$medium-up} {
          width: 49%;
        }
        .pr-label-control {
          text-align: $ldirection;
        }
      }
      .pr-cb-tag-container:nth-child(odd) {
        margin-#{$ldirection}: 0;
      }
      .pr-cb-tag-container:nth-child(even) {
        @media #{$medium-up} {
          margin-#{$ldirection}: 2%;
        }
      }
    }
    .pr-rating-stars {
      text-align: $ldirection;
      width: 100%;
    }
    .pr-helper-text,
    .pr-btn-default {
      text-align: $ldirection;
    }
    .pr-btn-add-tag {
      float: $ldirection;
      clear: both;
    }
    .pr-form-control-error-icon {
      #{$ldirection}: auto;
      #{$rdirection}: 10px;
    }
    .pr-file-input-label {
      min-width: auto;
    }
    .pr-label-control {
      span {
        color: $color-white;
      }
    }
    .pr-media-input-display-captionGroup {
      text-align: $ldirection;
    }
    .pr-media-input-display-captionGroup-buttons {
      float: $ldirection;
    }
  }
  .pr-btn-review {
    float: $ldirection !important;
  }
  .thank-you-page {
    .title,
    .subtitle,
    .review-preview,
    .headline,
    .pr-h5 {
      text-align: $ldirection;
    }
    .pr-rating-stars,
    img {
      float: $ldirection;
      clear: both;
    }
    #war-ty-pros {
      float: $ldirection;
      width: 100%;
      padding-#{$ldirection}: 10px;
    }
  }
}

#power_review_container {
  .product-full {
    .product-full {
      &__image {
        img {
          float: #{$ldirection};
        }
      }
      &__details {
        float: #{$ldirection};
      }
    }
  }
}
