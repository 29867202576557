.offer-section {
  .offer-code-panel {
    .checkout__panel__title {
      @media #{$medium-up} {
        font-size: 22px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.checkout__offer-code__wallet {
  visibility: hidden;
  width: 100%;
  overflow: hidden;
  margin-top: 3px;
  @media #{$small-only} {
    margin-bottom: 10px;
  }
  @media #{$medium-up} {
    height: 430px;
  }
  &.offer_applied {
    &.slick-slider {
      .checkout__offer-code__wallet-item {
        &[data-offer-code='lyl_expedited_fss_visible'],
        &[data-offer-code='lyl_fss_visible'] {
          .checkout__offer-code__wallet-apply {
            display: inline-block;
            border: 1px solid $color-navy;
            background: $color-white;
            color: $color-navy;
          }
        }
      }
    }
    //offer in cart
    .checkout__offer-code__wallet-item {
      .checkout__offer-code__wallet-apply {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        color: $color-white;
      }
    }
  }
  &-item {
    text-align: center;
    &__header {
      display: none;
    }
    &__image-container {
      padding: 0;
      margin-bottom: 16px;
      img {
        margin: 0 auto;
      }
    }
    &__inner {
      float: none;
      width: auto;
      padding: 0;
    }
    &__image {
      display: inline-block;
      width: 35%;
      background: $color-navy;
      @media #{$medium-up} {
        width: 144px;
      }
      &__title {
        color: $color-white;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        font-family: $font-bauerbodoni-roman;
        text-transform: uppercase;
        line-height: 1.1;
        letter-spacing: 0.02em;
        padding: 0 5px;
      }
    }
    &__description {
      font-family: $optimaregular;
      font-size: 15px;
      line-height: 23px;
      @media #{$medium-up} {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .loyalty__panel__offers__offer-code-wrap {
      @media #{$medium-up} {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        margin: 6px 0;
        font-family: $akzidenz;
        .loyalty__panel__offers__offer-code {
          font-weight: bold;
        }
      }
    }
    // buttons
    &__button-container {
      position: relative;
      margin: 0 10px 0;
      @media #{$medium-up} {
        text-align: center;
      }
      input[type='submit'] {
        border: 1px solid $color-navy;
      }
    }
    &.is-applied {
      .checkout__offer-code__wallet-apply {
        display: none;
      }
      .checkout__offer-code__wallet-remove {
        display: block;
      }
      .checkout__offer-code__wallet-selected {
        display: inline-block;
      }
      .checkout__offer-code__wallet-item__promo-applied {
        display: block;
        margin-top: 20px;
        font-size: 12px;
      }
    }
    &__button-loader {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center $color-white;
      z-index: 10;
      @media #{$medium-up} {
        background-color: $color-gray-cs-side;
      }
    }
    .checkout__offer-code__wallet-apply,
    .checkout__offer-code__wallet-remove {
      height: 33px;
      line-height: 33px;
    }
    .checkout__offer-code__wallet-apply {
      display: inline-block;
      border: 1px solid $color-navy;
      background: $color-white;
      color: $color-navy;
      padding: 0 20px;
      margin: 20px auto;
      width: auto !important;
    }
    .checkout__offer-code__wallet-remove {
      margin-top: 10px;
      display: none;
      border: none;
      height: 0;
      line-height: inherit;
      text-decoration: underline;
    }
    .offer-code-wallet__button--remove {
      margin: 10px 0 25px;
      border: none;
      line-height: inherit;
      text-decoration: underline;
    }
    .checkout__offer-code__wallet-selected {
      display: none;
      background: $color-navy;
      color: $color-white;
      pointer-events: none;
    }
    .checkout__offer-code__wallet-item__promo-applied {
      display: none;
    }
    .checkout__offer-code__wallet-item__link-container {
      margin-top: 20px;
    }
    .checkout__offer-code__wallet-item__link {
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  // slick
  .slick-prev {
    background-image: $sprites-sprites;
    background-position: 0 -8820px;
    width: 16px;
    left: -7px;
    top: 20%;
    @media #{$medium-up} {
      left: -20px;
      top: 30%;
    }
  }
  .slick-next {
    background-image: $sprites-sprites;
    background-size: auto auto;
    background-position: -14px -8600px;
    width: 16px;
    right: -7px;
    top: 20%;
    @media #{$medium-up} {
      right: -20px;
      top: 30%;
    }
  }
}

.viewcart,
.shipping,
#payment,
#review {
  .checkout__sidebar {
    width: 100%;
    @media #{$large-up} {
      width: 34.5%;
    }
    .loyalty-points-description__panel {
      padding: 10px 24px;
      font-size: 15px;
    }
    .tab-content {
      .samples-offer-carousel-panel {
        .panel--offer-code__header {
          &.checkout__panel__header {
            display: block;
            .checkout__panel__title {
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
  .loyalty-points-description__panel {
    padding: 10px 24px;
  }
  .loyalty-points__description {
    font-size: 15px;
  }
}

.offer-code__form {
  .offer-code {
    &__input {
      @media #{$medium-up} {
        margin-bottom: 0;
        float: #{$ldirection};
        width: 60%;
      }
    }
    &__submit {
      .form-submit {
        @media #{$medium-up} {
          @include swap_direction(margin, 0px 0px 0px 10px);
          width: 36%;
          height: 44px;
        }
        @media #{$large-up} {
          width: 30%;
        }
      }
    }
  }
}

.social-login__terms {
  .social-terms {
    font-style: initial;
    letter-spacing: 0.05em;
  }
}

.checkout {
  .accordionPanel {
    i {
      position: inherit;
    }
  }
  .checkout__sidebar {
    .samples-offer-carousel-panel {
      padding: 15px 0px;
    }
    .checkout__panel-title,
    .checkout__panel__title {
      padding-top: 13px;
      .title-icon {
        position: absolute;
        bottom: 0;
        top: 0;
        #{$rdirection}: -5px;
        width: 45px;
        height: 45px;
      }
      &.open {
        .title-icon {
          background-position: 0px -2957px;
        }
      }
    }
  }
  &.checkout__index-content {
    .checkout__sidebar,
    .checkout__panel-title {
      .title-icon {
        background-position: 0px -2957px;
      }
      &.open {
        .title-icon {
          background-position: 0px -3269px;
        }
      }
    }
    .social-login__divider {
      position: relative;
    }
  }
}

.samples-overlay {
  .samples-offer-carousel-panel {
    display: none;
  }
}

.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: #{$rdirection};
    #{$rdirection}: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-#{$ldirection}: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        text-transform: capitalize;
        letter-spacing: 1px;
        .js-loyalty-points-value {
          font-weight: bold;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    #{$rdirection}: 100px;
  }
  @media #{$large-only} {
    #{$rdirection}: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-#{$ldirection}: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
  }
  .sign-out-link {
    margin-#{$ldirection}: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
  }
  .user-loyalty-state {
    @media #{$medium-up} {
      z-index: 1;
      top: 35px;
    }
  }
}

.account-mobile-landing-page {
  .account-landing-profile__link {
    height: 45px;
    line-height: 1.25;
    width: 100%;
  }
}

.page-sticky-footer__left {
  a {
    font-weight: 700;
  }
}

.device-mobile {
  #colorbox {
    &.loyalty-offer-overlay {
      opacity: 1 !important;
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    #{$rdirection}: 20px;
    min-width: 200px;
    position: static;
  }
  .sign-out-link {
    line-height: 3.5;
  }
}

#loyalty__content {
  .is-member {
    .loyalty_tiers {
      a {
        display: block;
      }
    }
  }
}

.checkout__sidebar {
  .tab-switcher {
    @media #{$medium-up} {
      display: block !important;
    }
    .tab-bar {
      display: none;
      @media #{$large-up} {
        display: block;
      }
    }
    .tab-content {
      .samples-panel {
        display: none;
        @media #{$large-up} {
          display: block;
        }
      }
    }
  }
}

#loyalty-offer-panel {
  .loyalty_offer_module {
    .checkout__offer-code__wallet-count {
      display: none;
    }
  }
}

#shipping {
  .loyalty-panel {
    .checkout__subtitle {
      font-size: 13px;
      font-weight: 700;
      font-family: 'Optima Display Roman';
    }
  }
  .loyalty-points__description {
    font-size: 15px;
  }
}
