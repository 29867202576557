.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-link {
          @media only screen and (max-width: 736px) {
            float: left;
          }
        }
      }
    }
  }
}
