// Abstract specific font names away
$assistant: 'Assistant', sans-serif;
$bauerbodoni: 'Bauer Bodoni W01 Roman';
$frankruhllibre: 'Frank Ruhl Libre';
$optimadisplaylight: 'OptimaDisplayLight';

@font-face {
  font-family: 'Conv_OptimumLight';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Assistant';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('#{$netstorage-font-path}FrankRuhlLibre/FrankRuhlLibre-VariableFont_wght.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Futura Now Regular';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Futura Now Medium';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Optima Display Roman';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('#{$netstorage-font-path}Assistant/Assistant-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
}

body {
  font-family: $assistant;
  .page-wrapper *,
  .headline--senary,
  .text-benefits-line-sub {
    font-family: $assistant;
  }
  .product_brief {
    &__sub-header {
      font-family: $assistant;
    }
  }
  .product-result {
    &__sub-name {
      font-family: $assistant;
    }
  }
  .mpp__header,
  .checkout__panel-title,
  .product-full__title,
  .product-full__title span,
  .sidebar-page__content-title,
  .discover-more__header,
  #colorbox.colorbox__quickshop .quickshop h3,
  #colorbox.colorbox__quickshop .quickshop__sub-header {
    font-family: $optimadisplaylight, $assistant;
  }
  .checkout__sidebar {
    .links-panel__title,
    h2 {
      font-family: $assistant !important;
    }
  }
  .device-pc {
    .discover_more__product-sub-header,
    #colorbox.colorbox__quickshop .quickshop__tab--description {
      font-family: $assistant;
    }
  }
  .page-navigation {
    .content {
      .level-1,
      .level-2,
      .level-3 {
        font-family: $assistant;
      }
    }
    .depth-1 {
      .menu {
        h3 {
          font-family: $assistant;
        }
      }
    }
    .level-3 {
      font-family: $assistant;
    }
  }
  .product-full {
    &__review {
      font-family: $assistant;
    }
    &__attribute {
      p {
        font-family: $assistant;
      }
    }
    &__description {
      .spp-product__detail-link {
        font-family: $assistant;
      }
    }
  }
  .page-footer {
    .footer-header {
      font-family: $assistant;
    }
    .page-sticky-footer {
      .content,
      .field-menu {
        font-family: $assistant;
      }
      .drawer-formatter__content {
        .special-offer {
          .special-offer__header {
            font-family: $assistant;
          }
        }
      }
    }
    .country_chooser,
    .sms-signup,
    .email_signup {
      &__header {
        font-family: $assistant;
      }
    }
  }
  .checkout__sidebar {
    .links-panel {
      &__title {
        font-family: $assistant;
      }
    }
    h2 {
      font-family: $assistant;
    }
  }
  p {
    font-family: $assistant;
  }
  .page-wrapper {
    .boutique#supreme-power_container {
      h3,
      p {
        font-family: $assistant;
      }
      h2 {
        font-family: $assistant;
      }
      .rotating-quotes {
        p {
          font-family: $assistant;
        }
      }
      .supreme_english,
      h3.text-product-listing-names-main {
        font-family: $assistant;
      }
      .supreme_locale {
        font-family: $assistant;
      }
    }
  }
  .double-wear-cushion-stick-page {
    #double-wear-cushion-stick__hero-img-text1,
    #double-wear-cushion-stick__hero-img-text2,
    #double-wear-cushion-stick__hero-img-text3,
    #double-wear-cushion-stick__header1-mob,
    #double-wear-cushion-stick__header2-mob,
    #double-wear-cushion-stick__header3-mob,
    #double-wear-cushion-stick__header1,
    .double-wear-cushion-stick__body {
      font-family: $assistant;
    }
    #double-wear-cushion-stick__header2,
    #double-wear-cushion-stick__hero-img-text4 {
      font-family: $assistant;
    }
    .double-wear-cushion-stick__quick-shop,
    #double-wear-cushion-stick__hero-explore-text {
      font-family: $assistant;
    }
    #boutique-anr-simple-0914 {
      #sumptuous-knockout__mainText-expandable {
        font-family: $assistant;
      }
    }
    #classics__carousel-wrapper.cushion {
      #classics__carousel-sub-header1 {
        font-family: $assistant;
      }
      .type__standard-text {
        font-family: $assistant;
      }
    }
    .mpp__header.off {
      font-family: $assistant;
    }
    .boutique__section {
      #boutique_link {
        a {
          font-family: $assistant;
        }
      }
    }
  }
  @for $i from 4 through 7 {
    .double-wear-cushion-stick__body {
      #double-wear-cushion-stick__how-to-text#{$i} {
        font-family: $assistant;
      }
      #double-wear-cushion-stick__how-to-text1 {
        font-family: $assistant;
      }
      #double-wear-cushion-stick__how-to-text2,
      #double-wear-cushion-stick__how-to-text3 {
        font-family: $assistant;
      }
    }
  }
  .envy-lustre__frame {
    #envy-lustre__video-text {
      font-family: $assistant;
    }
    @for $i from 1 through 8 {
      .envy-lustre__parallax-copy {
        #envy-lustre__parallax-copy-#{$i} {
          font-family: $assistant;
        }
      }
    }
    @for $i from 1 through 3 {
      .envy-lustre__lipstick-header {
        #envy-lustre__lipstick-header#{$i} {
          font-family: $assistant;
        }
      }
    }
    .envy-lustre__lipgloss-intro-wrapper {
      #envy-lustre__lipgloss-intro,
      #envy-lustre__lipgloss-intro1,
      #envy-lustre__lipgloss-intro2 {
        font-family: $assistant;
      }
    }
  }
  #estee-stories-wrapper {
    .estee-stories-subheader {
      font-family: $assistant;
    }
  }
  .boutique#tmb_container {
    .mb_header p,
    .mb_boutique_content p,
    .mb_boutique_quote h3 {
      font-family: $assistant;
    }
  }
  .night-experience-page {
    #anr-night-experience__expandable-1-text15 {
      font-family: $assistant;
    }
    @for $i from 5 through 9 {
      #anr-night-experience__basic-image-text#{$i} {
        font-family: $assistant;
      }
    }
    .anr-night-experience {
      &__desc,
      &__headings,
      &_mini_product_info_price,
      &__edit_centre_title,
      &__edit_centre_sub_title,
      &__edit_read_article,
      &__text_best_night_content,
      &__text_plus_content,
      &__text_plus_content_ani {
        font-family: $assistant;
      }
    }
  }
  .product_brief__header {
    font-family: $assistant;
  }
  .product-brief {
    h3.product-brief__header {
      font-family: $optimadisplaylight, $assistant;
    }
  }
  h4.product-brief__sub-header {
    font-family: $assistant !important;
  }
  .ee-landing__text h4,
  h1.header-meta__headline.headline--primary,
  h2.header-meta__subhead.headline--tertiary,
  .ee-article-products__header h2,
  .product-full__subtitle,
  .product-full__detail-link {
    font-family: $optimadisplaylight, $assistant;
  }
  .ee-mb-tile__text h4 {
    font-family: $assistant;
  }
  h1.header__headline,
  h1.header__headline span,
  h1.header__headline span div,
  .basic-textarea-v1 h3,
  .product-full__title,
  .text-product-listing-names-main,
  .text-product-listing-names-main a,
  .spp-product__header {
    font-family: $optimadisplaylight, $assistant;
  }
  h1.header__headline span {
    .multiuse-a-anrampoules-32 &,
    .g-thegetupglow-48 & {
      font-family: $assistant;
      font-size: 120px;
      line-height: 120px;
    }
  }
  .customer-service {
    ul li {
      font-family: $assistant;
    }
    .faq h3 {
      font-family: $assistant;
    }
  }
  .back-to-top {
    font-family: $assistant;
  }
  .spp-product__details {
    .spp-product__details {
      &-attribute__label,
      &-description p {
        font-family: $assistant;
      }
      &-header {
        font-family: $optimadisplaylight, $assistant;
      }
    }
  }
  .page-navigation__hotswap {
    .depth-1.menu-container--has-nav-tout {
      .level-2 h3 {
        font-family: $assistant;
      }
      li.first a h3,
      li.last a h3 {
        font-family: $assistant;
      }
    }
  }
  .discover-more {
    .discover-more__header {
      font-family: $assistant;
    }
  }
  .spp-product {
    /* stylelint-disable selector-max-type, declaration-no-important */
    .spp-product {
      &__section,
      &__description {
        font-family: $assistant;
      }
      &__sub-header,
      &__header span,
      &__header {
        font-family: $optimadisplaylight, $assistant;
      }
      &__attribute p {
        font-family: $assistant;
      }
    }
    &__reviews .pr-header {
      font-family: $assistant !important;
    }
    /* stylelint-enable selector-max-type, declaration-no-important */
  }
  &.page-product-re-nutriv {
    .product-full__title,
    .product-full__title span,
    .headline--senary,
    .text-benefits-line-sub {
      font-family: $bauerbodoni, $frankruhllibre !important;
    }
  }
  .spp-product__attribute {
    .spp-product__detail-link {
      font-family: $assistant;
    }
  }
  &.device-mobile {
    .page-footer {
      .page-sticky-footer {
        .page-sticky-footer__right {
          .menu a {
            font-family: $assistant;
          }
        }
      }
    }
    .content {
      .footer-phone {
        .footer-phone__link {
          font-family: $assistant;
        }
      }
      .footer-main-menu .menu a,
      .footer-forms__header,
      .email_signup__header,
      .form-text,
      .form-submit {
        font-family: $assistant;
      }
      .responsive-carousel {
        .responsive-carousel__title {
          font-family: $assistant;
        }
        .slide__caption {
          font-family: $assistant;
        }
        .slide__link {
          font-family: $assistant;
        }
      }
    }
    .spp-product__reviews-header--mobile {
      font-family: $assistant;
    }
    #ultimate-diamond_container_mobile {
      .ultimate_section_mobile {
        a,
        p.italic,
        P {
          font-family: $frankruhllibre, $assistant;
        }
        h3 {
          font-family: $frankruhllibre, $assistant;
        }
        h4 {
          a {
            font-family: $frankruhllibre, $assistant;
          }
        }
      }
    }
  }
  .spp-discover-more-section-mobile {
    .discover-more {
      .discover_more__product {
        .discover_more__product-header {
          font-family: $assistant;
        }
        .discover_more__product-sub-header {
          font-family: $assistant;
        }
        .discover_more__product-price {
          font-family: $assistant;
        }
      }
    }
    .spp-product__section {
      h4.spp-product__section-header {
        font-family: $assistant;
      }
    }
  }
  .spp-product__description {
    .spp-product__detail-link {
      font-family: $assistant;
    }
  }
  .brand-renutriv {
    .page-main {
      h4.product_brief__sub-header {
        font-family: $frankruhllibre, $assistant;
      }
    }
  }
  .product-full__description {
    .product-full__detail-link {
      font-family: $assistant;
    }
  }
  &.page-products-re-nutriv,
  &.page-product-re-nutriv,
  &.brand-style-renutriv,
  &#{$renutriv},
  & #{$renutriv} {
    &.page-wrapper,
    .page-wrapper {
      .page-main {
        .mpp__header,
        .product_brief__header,
        .product-full__title,
        .product-full__title span,
        .spp-product__details-header,
        .discover-more__header,
        .discover_more__product-header,
        .spp-product__header span,
        .spp-product__header {
          font-family: $bauerbodoni, $frankruhllibre !important;
        }
        .product-full {
          &__subtitle,
          &__personal,
          &__attribute h5,
          &__attribute p,
          &__wishlist,
          &__add-button {
            font-family: $frankruhllibre, $assistant;
          }
        }
        .spp-product {
          &__details-attribute h5,
          &__details-attribute p,
          &__details-description p,
          &__details-description i,
          &__details-description,
          &__sub-header,
          &__attribute p,
          &__detail-link {
            font-family: $frankruhllibre, $assistant;
          }
        }
        .product_brief__price,
        .product_brief__misc-flag,
        .discover_more__product-sub-header,
        .free_standard_shipping_returns,
        .product_brief__buttons-container a,
        .product-brief__button-quickshop,
        .hero-tout__copy a {
          font-family: $assistant;
        }
        .product_brief__sub-header,
        .text-promo__subcopy {
          font-family: $frankruhllibre, $assistant;
        }
        h3.product-brief__header {
          font-family: $frankruhllibre;
        }
        h4.product-brief__sub-header,
        h4.spp-product__sub-header {
          font-family: $frankruhllibre, $assistant;
        }
        h1.header__headline {
          span {
            font-family: $frankruhllibre, $assistant;
          }
        }
      }
      #wrapper {
        #innerwrapper {
          .boutique {
            h3,
            h4 {
              &.propoText {
                font-family: $frankruhllibre, $assistant;
                span {
                  font-family: $frankruhllibre, $assistant;
                }
              }
            }
            p {
              &.propoText {
                font-family: $assistant;
              }
            }
            .propoScaled {
              a {
                font-family: $assistant;
              }
            }
            .side_nav_list {
              a {
                font-family: $assistant;
              }
            }
            .category_links {
              a {
                font-family: $frankruhllibre, $assistant;
              }
            }
          }
        }
      }
    }
    .sd-product-grid {
      .elc-grid-container {
        .elc-product-grid-header {
          font-family: $bauerbodoni, $assistant;
        }
      }
    }
  }
  .ee-landing__tile {
    .ee-landing__text {
      h4 {
        font-family: $assistant;
      }
    }
  }
  ul.product_brief__swatch-select-selectBox-dropdown-menu,
  ul.product-full__shade-select-selectBox-dropdown-menu,
  ul.shade-select-selectBox-dropdown-menu {
    li {
      a {
        font-family: $assistant;
      }
    }
  }
  .hero-block__promo-copy {
    .text-promo__subhead {
      font-family: $assistant;
      font-size: 17px;
    }
    .text-promo__subcopy {
      font-family: $assistant;
    }
  }
  .spp_howtouse__header {
    .headline--secondary {
      font-family: $assistant;
    }
  }
  .BVRRQuickTakeList {
    .BVRRTag span {
      font-family: $assistant;
    }
  }
  .BVCustomTitleWrapper {
    .BVRRTitle {
      font-family: $assistant;
    }
  }
  .spp-product__mini-bag-show {
    .spp-product__mini-bag-description {
      h5.spp-product__mini-bag-header {
        font-family: $assistant;
        font-size: 17px;
      }
      h6.spp-product__mini-bag-sub-header {
        font-family: $assistant;
        font-size: 13px;
      }
    }
  }
  .selectBox-options {
    li {
      a {
        font-family: $assistant;
      }
    }
  }
  .special-offers-tray {
    .gift-wrap,
    .special-offer-benefits {
      .gift-wrap__content,
      .special-offer-benefits__content {
        font-family: $assistant;
      }
      .gift-wrap__link-wrapper a,
      .special-offer-benefits__link-wrapper a {
        font-family: $assistant;
      }
    }
    .offers-email-signup-form,
    .offers-register-form,
    .offers-sign-in-form {
      .headline--offers-tray {
        font-family: $assistant;
      }
    }
    .offers-email-signup-form {
      .offers-email-signup-form__sign-in-wrapper {
        font-family: $assistant;
        a {
          font-family: $assistant;
        }
      }
    }
  }
  &.page-product-aerin,
  &.brand-aerin {
    .page-main {
      p,
      h1,
      h1 span,
      h2,
      h3,
      h4,
      h5,
      h6,
      select,
      a.selectbox {
        font-family: $optimadisplaylight, $assistant !important;
      }
      .headline--primary,
      .headline--large,
      .headline--products,
      .mpp__header,
      h3.product-full__title,
      .spp-product__header span,
      .spp-product__header {
        font-family: $optimadisplaylight, $assistant;
      }
      .spp-product__details {
        .spp-product__details-header {
          font-family: $optimadisplaylight, $assistant;
        }
      }
      .woa-landing__tile {
        .woa-landing__text {
          div,
          a.aerin-block-link {
            font-family: $assistant;
          }
        }
      }
      .spp-product__description {
        .spp-product__detail-link {
          font-family: $assistant;
        }
      }
      .text--short,
      .text-promo,
      label,
      .cart-item__qty {
        font-family: $assistant;
      }
      .product_brief__misc-flag,
      .product_brief__price {
        font-family: $assistant;
      }
      .headline--tertiary,
      .text-product-listing-names-main,
      .product_brief__header,
      .typeahead-wrapper .product-result__name {
        font-family: $optimadisplaylight, $assistant;
      }
      .section-store-locator.device-pc {
        .store-locator {
          .store-locator__title {
            font-family: $optimadisplaylight, $assistant;
          }
        }
      }
      .text-product-listing-names-sub,
      .ff-results__product .sku-brief-editorial__shadename,
      .beauty-feed-tout--products .sku-brief-editorial__shadename,
      .typeahead-wrapper .product-result__sub-name .product-result__sub-name,
      .product_brief__sub-header,
      .text--long-copy,
      .text-long-body-copy,
      .spp-product__details .spp-product__details-description {
        font-family: $assistant;
      }
      .product-brief__view-details,
      .product-full__detail-link,
      .product-full__share,
      .product-full__wishlist {
        font-family: $assistant;
      }
      .button {
        font-family: $assistant;
      }
    }
    .is-alternate .page-branding__logo {
      background: none !important;
    }
  }
  .ff-results__product,
  .beauty-feed-tout--products {
    .brand-aerin {
      .page-main {
        .sku-brief-editorial__shadename {
          font-family: $assistant;
        }
      }
    }
  }
  .typeahead-wrapper {
    .brand-aerin {
      .page-main {
        .product-result__sub-name {
          font-family: $assistant;
        }
        .product-result__name {
          font-family: $assistant;
        }
      }
    }
  }
  .section-store-locator.device-pc {
    .store-locator {
      .brand-aerin {
        .page-main {
          .store-locator__title {
            font-family: $assistant;
          }
        }
      }
    }
  }
}
/* stylelint-disable declaration-no-important */
.loyalty__panel {
  #loyalty__panel__how-to-earn &__how-to-earn__title,
  .loyalty-account-page #loyalty__page__history &_transactions__title {
    font-family: $assistant !important;
  }
}
/* stylelint-enable declaration-no-important */

#tips_boutique {
  .tips_section_wrapper {
    .tips_section {
      .header {
        h1.title {
          font-family: $assistant;
        }
      }
      .step {
        .step-text h3 {
          font-family: $assistant;
        }
      }
    }
  }
}

.section-envy-lustre {
  .envy-lustre__frame {
    .envy-lustre__parallax-copy {
      #envy-lustre__parallax-copy-2 {
        font-family: $assistant;
      }
    }
  }
}

.section-night-experience {
  #anr-night-experience__module1 {
    #anr-night-experience__carousel-1 {
      .anr-night-experience__carousel-4-text1,
      .anr-night-experience__carousel-4-text4 {
        font-family: $assistant;
      }
      .anr-night-experience__carousel-4-text2,
      .anr-night-experience__carousel-4-text3 {
        font-family: $assistant;
      }
    }
    #anr-night-experience__carousel-2 {
      #anr-night-experience__carousel-2-text1,
      #anr-night-experience__carousel-2-text2,
      #anr-night-experience__carousel-2-text3 {
        font-family: $assistant;
      }
      #anr-night-experience__carousel-2-text4 {
        #pSpan8 {
          font-family: $assistant;
        }
      }
    }
    #anr-night-experience__carousel-3 {
      #anr-night-experience__carousel-3-text1 {
        font-family: $assistant;
      }
    }
    #anr-night-experience__carousel-4 {
      #anr-night-experience__carousel-1-text1,
      #anr-night-experience__carousel-1-text2 {
        font-family: $assistant;
      }
      #anr-night-experience__carousel-1-text3,
      #anr-night-experience__carousel-1-text4 {
        font-family: $assistant;
      }
    }
  }
  #anr-night-experience__module2 {
    #anr-night-experience__scroll-text1,
    #anr-night-experience__scroll-text3,
    #anr-night-experience__scroll-text5 {
      font-family: $assistant;
    }
  }
  .anr-night-experience__module4 {
    #anr-night-experience__expandable-1-text15 {
      font-family: $assistant;
    }
    #anr-night-experience__expandable-1-text16,
    #anr-night-experience__expandable-1-text17,
    #anr-night-experience__expandable-2-text1 {
      font-family: $assistant;
    }
  }
  #anr-night-experience__module5 {
    #anr-night-experience__basic-image-text1,
    #anr-night-experience__basic-image-text2,
    #anr-night-experience__basic-image-text3 {
      font-family: $assistant;
    }
  }
  #anr-night-experience__module6 {
    #anr-night-experience__scroll2-text1,
    #anr-night-experience__scroll2-text3,
    #anr-night-experience__scroll2-text5 {
      font-family: $assistant;
    }
  }
  #anr-night-experience__module7 {
    #anr-night-experience__carousel2-text1 {
      font-family: $assistant;
    }
    #anr-night-experience__carousel2-text44,
    #anr-night-experience__carousel2-text4,
    #anr-night-experience__carousel2-text12 {
      font-family: $assistant;
    }
    #anr-night-experience__carousel2-text8,
    #anr-night-experience__carousel2-text20,
    #anr-night-experience__carousel2-text28,
    #anr-night-experience__carousel2-text36 {
      font-family: $assistant;
    }
    #anr-night-experience__carousel2-text46,
    #anr-night-experience__carousel2-text11,
    #anr-night-experience__carousel2-text47,
    #anr-night-experience__carousel2-text14 {
      font-family: $assistant;
    }
    #pSpan54,
    #anr-night-experience__carousel2-text53,
    #anr-night-experience__carousel2-text54,
    #anr-night-experience__carousel2-text55,
    #anr-night-experience__carousel2-text41,
    #anr-night-experience__carousel2-text33,
    #anr-night-experience__carousel2-text34,
    #anr-night-experience__carousel2-text25,
    #anr-night-experience__carousel2-text26,
    #anr-night-experience__carousel2-text17,
    #anr-night-experience__carousel2-text18 {
      font-family: $assistant;
    }
  }
  #anr-night-experience__module9 {
    .anr-night-experience__minicarousel_container {
      .anr-night-experience__headings {
        font-family: $assistant;
      }
      .anr-night-experience_mini_product_info_heading {
        font-family: $assistant;
      }
      .anr-night-experience_mini_product_info_body {
        font-family: $assistant;
      }
    }
  }
  #anr-night-experience__module10 {
    #anr-night-experience__edit_centre_title-2-2-d,
    #anr-night-experience__edit_centre_title-2 {
      font-family: $assistant;
    }
  }
  #anr-night-experience__module11 {
    .anr-night-experience__text_best_night_content,
    #anr-night-experience__text_plugged,
    #anr-night-experience__text_relax,
    #anr-night-experience__text_energized {
      font-family: $assistant;
    }
  }
}

#facechart-container {
  /* stylelint-disable declaration-no-important */
  .facechart-intro .column p {
    font-family: $assistant !important;
  }
  .facechart_carousel-text .steps li h2 {
    font-family: $assistant !important;
  }
  .facechart-products {
    h3 {
      font-family: $assistant !important;
    }
    li.el-product {
      h4,
      .el-product_price,
      .el-product_alt_description-text,
      .el-product_shop-now-container a {
        font-family: $assistant !important;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.button-explore-wrapper--mobile {
  .button-explore-label {
    font-family: $assistant;
  }
}

.button--dark,
.cta {
  font-family: $assistant;
}

.special-offer-row-mobile {
  .special-offer-content {
    .special-offer-button-wrapper {
      a.special-offer-button {
        font-family: $assistant;
      }
    }
  }
}

.continue-button-wrapper input[type='submit'],
.checkout .submit input[type='submit'] {
  font-family: $assistant;
}

.sample-select-button {
  &.selected {
    font-family: $assistant;
  }
}

.viewcart-buttons {
  .continue-checkout,
  .go-shopping {
    font-family: $assistant;
    letter-spacing: 0.12em !important;
  }
}
.sd-product-grid {
  .elc-product-grid-header-wrapper {
    .elc-product-grid-header {
      font-family: $optimadisplaylight, $assistant;
    }
  }
  .elc-grid-item-product {
    .elc-product-detail-wrapper,
    .elc-main-content-section {
      .elc-product-subheader,
      .elc-product-display-name-link {
        font-family: $optimadisplaylight, $assistant;
      }
      .elc-product-sub-display-name,
      .elc-product-description-wrapper {
        font-family: $assistant;
        font-weight: 700;
      }
    }
    .elc-product-display-name-link-subheader {
      font-family: $optimadisplaylight, $assistant;
    }
  }
  .brand-style-renutriv & {
    .elc-grid-container {
      .elc-main-content-section {
        .elc-product-subheader,
        .elc-product-display-name-link,
        .elc-product-display-name-link-subheader {
          font-family: $bauerbodoni, $frankruhllibre !important;
        }
      }
      .elc-product-brief .elc-short-description-container {
        .elc-product-short-description-wrapper {
          font-family: $assistant !important;
        }
      }
    }
  }

  .brand-aerin & {
    .elc-product-short-description-wrapper {
      font-family: $assistant !important;
    }
  }
}

.elc-responsive-modal-mask {
  .elc-product-quick-view-content {
    .elc-product-display-name-wrapper {
      .elc-product-subheader,
      .elc-product-sub-display-name,
      .elc-product-display-name-link {
        font-family: $optimadisplaylight, $assistant;
      }
      .elc-product-short-description-wrapper {
        font-family: $assistant;
      }
    }
  }
}
// Loyalty Font unification LOYAL-10069
// Pop-up Elist
.loyalty-offer-overlay {
  #cboxContent {
    #cboxLoadedContent {
      .loyalty_popover {
        .loyalty_popover__header {
          font-family: $assistant;
          span {
            font-family: $optimadisplaylight, $assistant;
          }
        }
      }
    }
  }
}
// account/loyalty/index.tmpl#about
.field-content {
  .loyalty_mrkt_ldng {
    .loyalty_tiers {
      .loyal-love-head {
        font-family: $assistant;
      }
      .tier {
        .loyalty-catgory-name {
          font-family: $assistant;
        }
      }
    }
    .loyalty_points {
      .points_earn {
        font-family: $assistant;
      }
    }
    .elist-banner {
      .elist-banner-header {
        font-family: $assistant;
      }
    }
  }
}
// /book-appointment
.appt-book .appointment-select {
  .appointments-container {
    .step-header,
    .sign-in-container .sign-in-link,
    .sign-in-container h4,
    #appt-book-sign-in-link {
      font-family: $assistant;
    }
  }
}
// /account/signin.tmpl
#signin,
#cboxContent {
  .sign-in-component {
    .sign-in-component__header {
      font-family: $assistant;
    }
  }
}
// registration pop-up
#cboxLoadedContent {
  .sign-in-component {
    .sign-in-component__confirm {
      .sign-in-component__registration-confirmation-header {
        font-family: $assistant;
      }
    }
  }
  .loyalty_popover .loyalty_popover__header {
    font-family: $assistant;
  }
}
//account/signin.tmpl#about non-loyalty user
#loyalty__content {
  .account-e-list {
    .e-list_not_signed {
      .e-list_not_signed-left__inner {
        .title_main {
          font-family: $assistant;
        }
      }
    }
  }
}
// account/loyalty/index.tmpl#rewards
#loyalty__panel__offers {
  .loyalty__panel__offers__list-container {
    .loyalty__panel__offers__offer-image-container {
      .checkout__offer-code__wallet-item__image__title {
        font-family: $assistant;
      }
    }
  }
  .loyalty__panel__offers__title {
    font-family: $assistant;
    span {
      font-family: $assistant;
    }
  }
}

#loyalty__panel__offers__sku {
  .loyalty__panel__offers__sku__title-container {
    .loyalty__panel__offers__sku__title {
      font-family: $assistant;
    }
  }
}

#loyalty__panel__transactions.is-user.is-member {
  .loyalty__panel__transactions__title {
    font-family: $assistant;
  }
}

.loyalty__content-container {
  #loyalty__content {
    #loyalty__panel__benefits {
      .loyalty__panel__benefits__title {
        font-family: $assistant;
      }
      .loyalty__panel__benefits__tier-level {
        font-family: $assistant;
      }
    }
  }
}
// account/loyalty/index.tmpl#history
.loyalty-account-page {
  .loyalty__content-container {
    #loyalty__page__history {
      .loyalty__panel__points__instruction__collect_points {
        font-family: $assistant;
      }
    }
  }
}
// /account/index.tmpl   loyalty user
#loyalty__panel__points {
  .loyalty__panel__points__title {
    &--account {
      font-family: $assistant;
    }
  }
}
// checkout
.enhanced_delivery_page {
  .pg_wrapper {
    .checkout__content {
      #content_wrapper {
        .loyalty-panel {
          .checkout__panel-title {
            font-family: $assistant;
          }
        }
      }
    }
  }
}
