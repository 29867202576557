.vto-skin-care-formatter {
  .elc-vto-skin-care {
    .sd-product-grid {
      .elc-grid-item-product {
        .elc-product-brief {
          .elc-carousel-section-wrapper {
            .elc-slider-view-wrapper {
              .slick-next {
                #{$ldirection}: 0;
                #{$rdirection}: auto;
              }
              .slick-prev {
                #{$rdirection}: 0;
              }
            }
          }
        }
      }
    }
    .elc-skin-care-result-title {
      font-family: $assistant, $optimalight;
    }
  }
  .elc-skin-care-result-blocks {
    .sd-product-grid {
      .elc-product-benefit-type {
        font-family: $assistant, $optimalight;
      }
    }
  }
}
