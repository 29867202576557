@mixin highlightLinks {
  color: Red !important;
  border-bottom: 2px solid red;
}

.INDhighlightLinks {
  .back-button__left-caret-icon {
    @include highlightLinks;
  }
  #cboxContent {
    #cboxClose {
      @include highlightLinks;
    }
  }
  .menu-item-container {
    @include highlightLinks;
  }
  .page-utilities {
    .page-utilities__cart-icon {
      @include highlightLinks;
    }
  }
  .header-items {
    .page-navigation__menu-toggle {
      .page-navigation__menu-icon {
        @include highlightLinks;
      }
    }
  }
}

.INDwhiteblack {
  .cart-item__qty {
    top: 0px;
  }
  .is-alternate {
    input[type='checkbox'] ~ .label::before {
      background: white !important;
    }
  }
  span.page-navigation__menu-icon {
    background-color: floralwhite !important;
  }
  .spp-product__description {
    button.js-add-to-cart {
      outline: 1px dashed MidnightBlue;
    }
    button.js-add-to-cart:hover {
      background-color: LemonChiffon !important;
    }
  }
}

.INDblackwhite {
  .registration-page {
    .registration-page__content {
      .registration-page__personal-info {
        .registration-page__title_container {
          .title_container {
            input[type='radio'] ~ label::after {
              background: gold;
            }
          }
        }
      }
    }
  }
  .page-utilities__cart-icon {
    background-image: url(/media/boutiques/anr-night-experience/assets/bag-white.png) !important;
  }
  .page-utilities__cart-icon:hover {
    background-color: LemonChiffon !important;
  }
  .el-mobile-search-block__btn-submit {
    background-image: url(/media/boutiques/anr-night-experience/assets/search-white.png) !important;
  }
  .el-mobile-search-block__btn-submit:hover {
    background-color: LemonChiffon !important;
  }
  .page-branding__logo {
    background: url(/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-small-alt.png) !important;
  }
  .page-branding__logo:hover {
    background-color: LemonChiffon !important;
  }
  .page-header {
    .header-items {
      .page-navigation__menu-icon {
        height: 22px;
        background-color: white !important;
      }
    }
  }
  .page-wrapper {
    .el-mobile-search-block {
      .el-mobile-search-block__container {
        .search-input-container {
          .mobile-form-text {
            background-color: white !important;
          }
        }
      }
    }
    .registration-page__content {
      #registration {
        .email-promotions,
        .sms_promo_container {
          input[type='checkbox'] ~ .label::after {
            background-color: gold !important;
          }
        }
      }
    }
  }
  .checkout-table {
    .cart-item {
      .cart-item__qty {
        top: 0px !important;
      }
    }
  }
  .spp-product__description {
    button.js-add-to-cart {
      outline: 1px dashed gold;
    }
    button.js-add-to-cart:hover {
      background-color: LemonChiffon !important;
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  .homepage-product-mobile__hero--b {
    z-index: 1;
  }
}

.INDblackcursor {
  .responsive-footer {
    .footer-signup-email {
      .email-signup {
        .email-signup__section {
          .email_signup__terms {
            input[type='checkbox'] ~ .label {
              cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
            }
          }
        }
      }
    }
    .footer-signup-sms {
      .sms-signup {
        .sms-signup__section {
          .sms-signup__terms {
            input[type='checkbox'] ~ .label {
              cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
            }
          }
        }
      }
    }
  }
}

.INDwhitecursor {
  .responsive-footer {
    .footer-signup-email {
      .email-signup {
        .email-signup__section {
          .email_signup__terms {
            input[type='checkbox'] ~ .label {
              cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
            }
          }
        }
      }
    }
    .footer-signup-sms {
      .sms-signup {
        .sms-signup__section {
          .sms-signup__terms {
            input[type='checkbox'] ~ .label {
              cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
            }
          }
        }
      }
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  .local-search__map-container {
    .local-search__map {
      div:not(img) {
        background-color: transparent !important;
      }
      div:nth-child(4) {
        div:nth-child(4) {
          div:nth-child(1) {
            div:nth-child(1) {
              div {
                background-color: grey !important;
              }
              div:nth-child(2) {
                div {
                  background-color: grey !important;
                }
              }
              div:nth-child(1) {
                div {
                  background-color: grey !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.brand-renutriv.INDblackwhite {
  .page-branding__logo {
    background: url(/sites/esteelauder/themes/estee_base/img/sprites/logo_renutriv.png) !important;
  }
}

.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .formatter-estee-edit {
    .ee-logo-mobile {
      padding-top: 16px;
    }
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .field-mobile-template {
    .hero-tout-mobile {
      max-width: 380px;
    }
  }
  .homepage-bricks {
    .homepage-product-mobile__content--b {
      margin-top: 110px !important;
    }
    .headline--medium {
      line-height: 60px;
      letter-spacing: -5px !important;
      left: 0 !important;
      top: -12% !important;
    }
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    svg {
      height: 35px;
    }
  }
}
