.sign-in-page,
.signin-overlay-wrapper {
  .sign-in-component {
    .signin-overlay {
      &__toggle-form {
        font-size: 12px;
      }
    }
    .forgot-password {
      .error_messages_display {
        color: $color-red;
      }
    }
    color: $color-navy;
    @media #{$medium-up} {
      padding: 30px;
      width: 585px;
    }
    &__form {
      margin: 0;
      .field[type='text'],
      .field[type='email'],
      .field[type='password'],
      .field[type='tel'],
      .form-text,
      .form-submit {
        @include box-shadow(none);
        @include input-placeholder {
          line-height: normal;
        }
        width: 100%;
        height: 40px;
        @media #{$small-only} {
          @include input-placeholder {
            color: $color-light-gray;
            background-color: $color-white;
          }
          height: 48px;
          border-color: $color-light-gray;
          &.error {
            border-color: $color-red;
          }
        }
      }
      &--sign-in,
      &--registration {
        padding: 0px 30px;
        @media #{$medium-up} {
          padding: 0;
        }
      }
      &-content {
        @media #{$medium-up} {
          margin: 0px 30px;
          padding: 0px 30px;
        }
        .sign-in-component__header {
          margin: 8px 0px;
          width: auto;
          font-family: $optimalight;
          font-weight: normal;
          font-size: 40px;
          letter-spacing: -0.05em;
        }
        .sign-in-component {
          .form-submit {
            margin-bottom: 0;
          }
          &__sms_promo,
          &__phone-wrapper {
            float: $rdirection;
            display: none;
          }
          &__phone-wrapper {
            margin-top: -9px;
            width: 200px;
          }
          &__communication_consent,
          &__registration_notice {
            font-size: 12px;
            @media #{$medium-up} {
              width: 85%;
            }
            overflow: hidden;
            &__notice {
              margin-bottom: 10px;
            }
          }
          &__password-wrapper {
            @include swap_direction(margin, 0px 0px 10px);
            position: relative;
            .form-text {
              margin: 0;
            }
            .visible-pass-wrapper {
              .form-text {
                @media #{$medium-up} {
                  border-color: $color-black;
                  color: $color-black;
                }
              }
            }
          }
          &__show-password-wrapper {
            position: absolute;
            #{$rdirection}: 12px;
            top: 2px;
            @media #{$small-only} {
              top: 15px;
            }
            label {
              &:before,
              &:after {
                visibility: hidden;
              }
            }
          }
          &__registration-options {
            width: auto;
          }
          &__header {
            font-family: $font-bauerbodoni-roman;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.02em;
            @media #{$medium-up} {
              text-align: #{$ldirection};
            }
          }
        }
        .text--form-help {
          margin-bottom: 10px;
          label {
            letter-spacing: 0.05em;
          }
        }
        .text--checkbox-label {
          label {
            &.error a {
              color: $color-red;
            }
          }
        }
        h6 {
          font-family: $akzidenz;
          margin-bottom: 10px;
          letter-spacing: 0.05em;
        }
      }
      &-loyalty {
        display: none;
      }
    }
    &.signin-join-loyalty {
      .sign-in-component {
        &__confirm--join-loyalty {
          display: block;
        }
      }
      &.sign-in {
        .sign-in-component__form--sign-in {
          display: none;
        }
      }
    }
    &__confirm--registration,
    &__confirm--join-loyalty {
      display: none;
    }
  }
  .error_messages {
    text-align: #{$ldirection};
  }
  .sign-in-component__form-content {
    padding: 0;
    .signin-loyalty-cta {
      .signin-loyalty-cta {
        &__button {
          margin: 20px 0px;
        }
        &__header {
          margin: 8px 0px;
          width: auto;
          font-family: $font-bauerbodoni-roman;
          font-weight: 400;
          font-size: 38px;
          letter-spacing: -0.05em;
          text-transform: uppercase;
        }
        &__terms {
          width: auto;
        }
        &__sub-header {
          padding-bottom: 30px;
        }
        &__link {
          font-weight: 700;
        }
      }
    }
  }
}

.signin-overlay {
  &-wrapper {
    border: 1px solid $color-medium-gray;
    .sign-in-component {
      @media #{$medium-up} {
        width: 546px;
      }
      &__form {
        margin: 0;
        padding: 0;
        .signin-overlay__toggle-form {
          display: none;
        }
        &-content {
          @media #{$small-only} {
            @include swap_direction(padding, 30px 30px 0px);
          }
        }
      }
      &--loyalty {
        @media #{$medium-up} {
          padding: 30px 10px;
        }
        &.fb-enabled {
          &.sign-in {
            @media #{$medium-up} {
              height: 590px !important;
            }
          }
        }
      }
    }
    &#colorbox {
      position: absolute !important;
    }
    #cboxContent {
      padding: 0;
      #cboxClose,
      #cboxClose:hover {
        #{$rdirection}: 20px;
        top: 20px;
      }
    }
    #cboxLoadedContent {
      @media #{$small-only} {
        width: 100% !important;
        height: 100% !important;
        overflow: scroll !important;
      }
    }
    .sign-in-component--loyalty {
      &.registration {
        &.sign-in {
          .signin-overlay__toggle {
            .signin-overlay__toggle {
              &-form {
                &--have-account {
                  display: none;
                }
                &--dont-have-account {
                  display: block;
                  text-align: #{$ldirection};
                  font-size: 12px;
                }
              }
            }
          }
        }
        .signin-overlay__toggle {
          .signin-overlay__toggle {
            &-form {
              &--have-account {
                display: block;
                text-align: #{$ldirection};
                font-size: 12px;
              }
              &--dont-have-account {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &__toggle-form {
    margin: 20px 0px;
    padding: 0px 30px;
    text-align: center;
    &--have-account {
      display: block;
      &-signin {
        font-size: 12px;
        color: $color-navy;
        background: transparent;
      }
      .sign-in & {
        display: none;
      }
    }
    &--dont-have-account {
      display: none;
      &-register,
      &-signin {
        font-size: 12px;
        color: $color-navy;
        background: transparent;
      }
      .mobile_hidden {
        @media #{$medium-up} {
          display: inline;
        }
      }
    }
  }
}
/* Account sign in page */
.sign-in-page--loyalty {
  max-width: 1366px;
  margin: 0 auto;
  &-container-banner {
    display: none;
    @media #{$medium-up} {
      width: 54%;
      float: #{$ldirection};
      display: block;
      background-image: url('/media/loyalty/tablet-potrait.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      height: 680px;
      @if $loyalty-banner-cr23update {
        background-image: url('#{$elist-banner-account-login}');
      }
    }
    @media #{$large-up} {
      @if $loyalty-banner-cr23update == false {
        background-image: url('/media/loyalty/tablet-landscape.jpg');
      }
      height: 825px;
    }
    @media #{$xlarge-up} {
      width: 50%;
      background-image: url('/media/loyalty/account-login.jpg');
      max-width: 668px;
      @if $loyalty-banner-cr23update {
        background-image: url('#{$elist-banner-account-login}');
      }
    }
  }
  &--header {
    display: none;
    @media #{$medium-up} {
      font-size: 3.6vw;
      font-family: $font-bauerbodoni-roman;
      letter-spacing: 0.09em;
      text-align: center;
      color: $color-white;
      padding-top: 44vw;
      display: block;
      @if $loyalty-banner-cr23update {
        padding-top: 220px;
        font-size: 30px;
        color: $color-black;
      }
    }
    @media #{$medium-down} {
      padding-top: 180px;
    }
    @media #{$large-up} {
      @if $loyalty-banner-cr23update == false {
        padding-top: 41vw;
      }
    }
    @media #{$xlarge-up} {
      padding-top: 28vw;
      @if $loyalty-banner-cr23update {
        padding-top: $elist-banner-account-login-desktop-padding-top;
        font-size: 35px;
      }
    }
    @media #{$xxlarge-up} {
      @if $loyalty-banner-cr23update == false {
        font-size: 50px;
        padding-top: 380px;
      }
    }
  }
  &--sub--content {
    display: none;
    @media #{$medium-up} {
      margin: 0;
      text-align: center;
      color: $color-white;
      font-weight: 500;
      font-size: 1.3vw;
      display: block;
      @if $loyalty-banner-cr23update {
        color: $color-black;
        margin-top: 5px;
      }
    }
    @media #{$xxlarge-up} {
      font-size: 18px;
    }
  }
  .sign-in-component--loyalty {
    @media #{$medium-up} {
      width: 46%;
      padding: 15px;
      max-width: 574px;
    }
    @media #{$large-up} {
      padding: 20px;
    }
    &.registration {
      &.sign-in {
        .signin-overlay__toggle-form {
          &--have-acount {
            display: none;
          }
          &--dont-have-acount {
            display: block;
            @media #{$medium-up} {
              padding: 0;
              text-align: #{$ldirection};
              font-size: 12px;
            }
          }
        }
      }
      .signin-overlay__toggle-form {
        margin: 15px 0px;
        &--have-acount {
          display: block;
          @media #{$medium-up} {
            padding: 0;
            text-align: #{$ldirection};
            font-size: 12px;
          }
        }
        &--dont-have-acount {
          display: none;
        }
      }
    }
    .sign-in-component {
      &__form {
        margin: 0;
        .field[type='text'],
        .field[type='email'],
        .field[type='password'],
        .field[type='tel'],
        .form-text,
        .form-submit {
          @include box-shadow(none);
          @media #{$medium-up} {
            width: 90%;
            height: 4vw;
            line-height: 0;
          }
          @media #{$large-up} {
            width: 100%;
            height: 40px;
          }
          @media #{$xlarge-up} {
            width: 85%;
            height: 40px;
          }
        }
      }
      &__password-wrapper {
        position: relative;
      }
      &__show-password-wrapper {
        position: absolute;
        top: 12px;
        #{$rdirection}: 12px;
        @media #{$medium-up} {
          top: 0;
          #{$rdirection}: 22%;
        }
        .text--checkbox-label {
          &:after,
          &:before {
            display: block;
          }
        }
      }
      &__registration-options {
        @media #{$medium-up} {
          width: 100%;
        }
        .text--form-help {
          @media #{$medium-up} {
            width: 85%;
          }
          &.sign-in-component__sms_promo {
            width: 110px;
          }
        }
      }
      &__header {
        width: auto;
        font-family: $font-bauerbodoni-roman;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: 0.02em;
        line-height: 1;
        @media #{$medium-up} {
          text-align: #{$ldirection};
          font-size: 25px;
          margin-bottom: 10px;
        }
        @media #{$large-up} {
          font-size: 38px;
          width: 100%;
        }
        @media #{$xlarge-up} {
          font-size: 40px;
          width: 85%;
        }
      }
      &__subheader {
        margin-bottom: 10px;
        font-size: 15px;
        letter-spacing: 0.05em;
        font-family: $akzidenz;
        @media #{$medium-up} {
          width: 85%;
        }
      }
    }
  }
  .social-login {
    .social-login {
      &__container {
        padding: 0px 30px;
        text-align: #{$ldirection};
        @media #{$medium-up} {
          padding: 0;
          margin: 0;
          width: 100%;
        }
        @media #{$xlarge-up} {
          width: 85%;
        }
      }
      &__email-opt-in {
        padding-top: 15px;
        @media #{$medium-up} {
          text-align: #{$ldirection};
          width: 100%;
        }
        @media #{$xlarge-up} {
          width: 85%;
        }
      }
      &__divider {
        @media #{$medium-up} {
          width: 100%;
        }
      }
    }
  }
}

.sign-in-page {
  &.sign-in-page--loyalty {
    &.sign-in-component__confirm {
      &--join-loyalty {
        padding: 0px 20px;
        display: block;
        text-align: center;
        @media #{$medium-up} {
          margin-top: 25%;
          text-align: #{$ldirection};
        }
        .signin-loyalty-cta {
          &__button {
            color: $color-white;
            @media #{$medium-up} {
              width: 150px;
            }
          }
        }
        .error_messages {
          padding: 0;
          margin: 0;
        }
      }
    }
    .sign-in-component {
      .form-submit {
        margin-bottom: 10px;
        width: auto !important;
      }
    }
  }
}
/* Sign in popup thank you message */
.signin-overlay-wrapper {
  .sign-in-component {
    &--loyalty {
      &.registration-confirmation {
        padding: 20px 10px;
      }
    }
    .sign-in-component {
      &__confirm {
        .sign-in-component {
          &__header {
            margin-bottom: 15px;
          }
          &__registration-confirmation-copy {
            display: block;
            font-size: 15px;
            letter-spacing: 0.05em;
            font-family: $akzidenz;
            font-style: italic;
            font-weight: 100;
          }
          &__registration-confirmation-header {
            font-family: $font-bauerbodoni-roman;
            font-size: 32px;
            font-style: italic;
            font-weight: 100;
          }
          &__confirm-registration-fields {
            display: flex;
            flex-direction: column;
            max-width: 540px;
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          &__birthday-program {
            float: none;
            width: 100%;
            padding: 0;
            order: 1;
            .select-month,
            .select-day {
              &.error {
                ~ .select-month,
                ~ .select-day {
                  border: 1px solid $color-red;
                }
              }
            }
          }
          &__confirm-options {
            max-width: 540px;
            .button {
              float: #{$ldirection};
              margin-top: 10px;
              width: auto;
            }
          }
          &__sms-opt-in {
            width: 100%;
            float: none;
            padding: 0;
            order: 2;
            margin-bottom: 0;
          }
          &__completion-error {
            color: $color-red;
            padding-top: 30px;
            line-height: 1.2;
          }
          &__mobile-conditions {
            line-height: 1.5;
          }
        }
        .error_messages_display {
          li {
            color: $color-red;
            font-size: 12px;
            line-height: 1.5;
            font-weight: 100;
            letter-spacing: 0.05em;
            font-family: $akzidenz;
          }
        }
      }
    }
  }
}

#colorbox.loyalty-offer-overlay {
  height: 600px !important;
  #cboxWrapper {
    height: 600px !important;
    #cboxMiddleLeft {
      height: 600px !important;
    }
    #cboxContent {
      height: 600px !important;
      .loyalty_popover {
        font-size: 12px;
        .sms-containter {
          display: none;
        }
        &__gdpr_communication {
          margin-top: 8px;
        }
        &__checkbox-container {
          margin-bottom: 8px;
        }
        &__input {
          input[type='email'] {
            width: 100%;
          }
        }
        .loyalty_popover__welcome {
          .loyalty_popover__firstname {
            width: 268px;
          }
        }
      }
    }
  }
}

#colorbox.signin-overlay-wrapper {
  width: 700px !important;
  height: 430px !important;
  @media #{$medium-up} {
    margin-left: 161px;
  }
  #cboxWrapper {
    width: 700px !important;
    @media #{$large-up} {
      height: 430px !important;
    }
    #cboxContent {
      width: 700px !important;
      @media #{$large-up} {
        height: 430px !important;
      }
      #cboxLoadedContent {
        width: 700px !important;
        @media #{$large-up} {
          height: 430px !important;
        }
      }
    }
  }
}

#cboxLoadedContent {
  .loyalty_popover__input {
    a {
      color: $color-navy;
    }
    .info-link-gdpr {
      color: $color-periwinkle-blue;
    }
  }
}
