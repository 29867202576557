// Loyalty Progress Bar
#loyalty__content {
  .chart {
    display: none;
  }
  // My Rewards
  #loyalty__panel__notification {
    &.is-user {
      &.is-member {
        background: url('/media/export/cms/EL_IL_loyalty_inner_page_mobile_1280x600.jpg');
        height: 47vw;
        background-size: cover;
        @media #{$medium-up} {
          background: url('/media/export/cms/EL_IL_loyalty_inner_page_desktop_1053X410.jpg') no-repeat;
          background-size: cover;
          height: 410px;
        }
      }
    }
  }
  #loyalty__page__index {
    &.loyalty-landing-menu {
      display: none;
    }
    #loyalty__panel__offers__sku {
      .loyalty__panel__offers__sku {
        &__product-wrapper {
          padding-top: 0;
        }
        &__sub-title {
          margin-bottom: 25px;
        }
      }
    }
  }
  .e-list_not_signed_wrapper {
    #label-site_sms_promo_checkbox,
    #label-site_pc_promo_checkbox {
      display: block;
      text-align: right;
      width: auto;
    }
    .e-list_not_signed-left {
      @media #{$medium-up} {
        margin-right: auto;
        margin-left: auto;
        float: none;
      }
      @media #{$xxlarge-up} {
        margin-left: 0;
        width: 37%;
      }
    }
    .e-list_not_signed-right {
      @media #{$xxlarge-up} {
        margin-#{$ldirection}: auto;
        width: 20%;
      }
      .info-link-gdpr {
        @media #{$xxlarge-up} {
          margin: 10px 0;
        }
      }
    }
    .force_loyalty_policy_agree-wrapper {
      padding-bottom: 15px;
    }
  }
  .e-list_not_signed {
    .btn_join {
      &--disabled {
        background-color: $color-gray;
      }
    }
  }
  #loyalty__panel__benefits {
    .loyalty__panel__benefits__description {
      margin-right: 20px;
    }
    .loyalty_points {
      display: block;
      text-align: center;
    }
  }
}

.loyalty__panel__notification {
  &__welcome {
    display: none;
  }
}
// Checkout
.checkout__offer-code__wallet-item__inner {
  .more_link {
    display: none;
  }
}

.checkout__offer-code__wallet {
  @include breakpoint($small-max) {
    margin-bottom: 40px;
  }
}

#loyalty__page__index,
#shipping,
#payment,
#viewcart {
  & .slick-prev {
    right: auto;
    z-index: 999;
  }
}

body#signin {
  &.device-mobile .info-msg-gdpr {
    right: 0;
    transform: translate3d(-27px, 0, 0);
    left: auto;
  }
  &.device-pc .info-msg-gdpr {
    right: auto;
    transform: translate3d(17px, 0, 0);
  }
  .sign-in-page {
    .sign-in-component {
      @media #{$device-ipad_only} {
        width: 46%;
        padding: 20px;
      }
    }
  }
}

#colorbox.signin-overlay-wrapper,
#colorbox.signin-overlay-wrapper #cboxWrapper,
#colorbox.signin-overlay-wrapper #cboxWrapper #cboxContent,
#colorbox.signin-overlay-wrapper #cboxWrapper #cboxContent #cboxLoadedContent {
  @media #{$medium-up} {
    height: 680px !important; // we need to override inline styling and other !important set higher, why noone earlier used $.colorbox.resize() method ? ;/
  }
}

#colorbox.signin-overlay-wrapper.signin-overlay-loyalty {
  @media #{$medium-up} {
    top: 50% !important;
    transform: translatey(-50%);
  }
}

// Popover
.loyalty_popover__checkbox-container {
  display: none;
}

.loyalty_popover__header {
  margin-top: 1em;
  padding-right: 0.2em;
}

// Account Page
#loyalty__page__tout {
  #loyalty__panel__points {
    .loyalty__panel__points__title--loyalty {
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
  }
}

.viewcart {
  #checkout-sidebar {
    #offer-panel {
      .title-icon {
        background-image: none;
      }
    }
    .offer-code-panel__title {
      .title-icon {
        left: -10px;
        right: unset;
        width: 15px;
      }
      .offer-code-panel__title.open {
        .title-icon {
          width: 15px;
        }
      }
    }
  }
}

#signin {
  .password-field__info {
    right: 0;
    left: unset;
    top: 40px;
  }
  .password-field__info::before {
    display: none;
  }
}

.shipping-layout-section {
  .loyalty-join-cart {
    display: none;
  }
}

// Ribbon - My Feed
.my-feed-loyalty__status-next {
  display: flex;
  align-items: center;
}

.sign-in-component__confirm--registration {
  .sign-in-component__birthday-program-copy {
    display: none;
  }
}

// Registration Page
.registration-page {
  .registration-page__birthday {
    .selectbox {
      @media #{$small-only} {
        background-position: 25% 75%;
      }
    }
  }
}

#cboxLoadedContent {
  // Birthday Pop-up
  .sign-in-component__confirm-registration-field-wrapper.sign-in-component__sms-opt-in {
    display: none;
  }
  .sign-in-component__confirm-options {
    padding-top: 30px;
  }
  // Welcome Pop-up
  .loyalty_popover {
    .validation_error_email_address {
      margin-top: 10px;
    }
    #home_popup_newsletter_email_input {
      width: 100%;
      border: solid 1px $color-light-gray;
      color: $color-navy;
      margin-bottom: 10px;
      margin-top: 10px;
      box-shadow: none;
    }
  }
}

.device-pc.elc-user-state-logged-in {
  #colorbox {
    #cboxClose {
      left: unset;
      right: 10px;
      top: 10px;
    }
  }
  .info-msg-gdpr {
    @media #{$medium-up} {
      #{$ldirection}: auto;
    }
  }
}

//loyalty LP
.node-49724 {
  .loyalty_mrkt_ldng {
    .loyalty_points {
      width: 100%;
    }
    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media #{$medium-up} {
        padding: 40px 0;
      }
      .content-block-tout--v1 {
        flex: 0 1 100%;
        @media #{$xlarge-up} {
          flex: 1;
          margin: 0 10px;
        }
        .content-block-tout__inner {
          margin: 5px auto;
          @media #{$xlarge-up} {
            margin: auto;
          }
        }
      }
    }
  }
}

.loyalty__panel__about__landing {
  .node-49724 {
    .loyalty_mrkt_ldng {
      width: 100%;
      padding: 15px;
    }
  }
}

// Checkout confirmation page
#confirmation-page {
  .loyalty-find-out-more {
    margin-bottom: 80px;
    font-weight: 600;
  }
  .third-header {
    margin-bottom: 50px;
    font-size: 35px;
    font-weight: 600;
    margin-top: 120px;
    text-transform: uppercase;
  }
  .sub_form {
    display: none;
  }
  .top-banner {
    padding-left: 24px;
    padding-right: 24px;
    @media #{$medium-up} {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media #{$medium-up} {
  .customer-service {
    .sidebar-page__content {
      ol {
        li {
          min-height: unset;
          list-style-type: none;
        }
      }
    }
  }
}

// 'Close' button on sign-in and sign-up popup
.signin-overlay {
  &-wrapper {
    #cboxContent {
      #cboxClose,
      #cboxClose:hover {
        right: 20px;
      }
    }
  }
}

.device-mobile {
  .account-landing-profile {
    .view_account_profile_text {
      display: none;
    }
  }
}

.loyalty_mrkt_ldng {
  &.is_loyalty_member {
    .loyalty_tiers a {
      display: block;
    }
  }
  .elist-banner {
    .elist {
      &-banner-header {
        @media #{$medium-up} {
          margin-#{$ldirection}: 55%;
        }
        @media #{$xlarge-up} {
          font-size: 41px;
        }
      }
      &-banner-content {
        @media #{$medium-up} {
          margin-#{$ldirection}: 55%;
          width: 43%;
        }
        @media #{$large-up} {
          font-size: 18px;
        }
        @media #{$xxlarge-up} {
          width: 45%;
        }
      }
    }
  }
}

.loyalty-account-page {
  .loyalty__panel__about__landing {
    .loyalty_mrkt_ldng {
      .elist-banner {
        .elist {
          &-banner-header,
          &-banner-content {
            @media #{$medium-up} {
              margin-#{$ldirection}: 48%;
            }
            @media #{$xxlarge-up} {
              margin-#{$ldirection}: 55%;
            }
          }
        }
      }
    }
    .field-mobile-template {
      .loyalty_mrkt_ldng {
        .elist-banner {
          height: 100vw;
          background-size: cover;
          .elist {
            &-banner-header,
            &-banner-content {
              margin-#{$ldirection}: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.field-mobile-template {
  .loyalty_mrkt_ldng {
    .elist-banner {
      height: 100vw;
      background-size: cover;
      max-height: inherit;
      &-header {
        width: 100%;
      }
    }
  }
}

.loyalty-body {
  &.sidebar-page__content {
    .loyalty_mrkt_ldng {
      .content-block-tout__inner {
        position: relative;
        height: 100%;
      }
      .content-block-tout__media {
        position: relative;
        z-index: 1;
        @media #{$medium-up} {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
      .mantle-media-asset {
        position: relative;
        @media #{$medium-up} {
          position: absolute;
          height: 100%;
        }
        picture {
          @media #{$xlarge-up} {
            display: flex;
            height: 100%;
          }
          img {
            object-fit: cover;
          }
        }
      }
      .content-block-tout__text {
        position: absolute;
        top: 0;
        z-index: 2;
        padding: 0 20px;
        @media #{$medium-up} {
          position: relative;
          padding: 0;
        }
      }
      .loyalty_tiers {
        padding: 0 10px;
        .tier {
          padding-bottom: 15px;
        }
      }
    }
  }
}
