button {
  &#ot-sdk-btn {
    &.ot-sdk-show-settings {
      background-size: 15px !important;
      padding: 0 30px !important;
      height: 30px !important;
      font-weight: bold;
      cursor: pointer !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      border: 1px solid $color-light-gray !important;
      text-decoration: none;
    }
  }
}
